<template>
  <div class="goods" v-if="isShowPage">
    <!-- 20220119 商品 推广 -->
    <div class="goods-tjcont" v-if="goodsData.clerk">
      <p class="goods-tjcont-p" v-html="clerkContent"></p>
      <div class="flex flex-right goods-tjcont-btn">
        <div class="flex flex-center align-center goods-tjcont-btn-item" @click="copyFunc">复制文案</div>
      </div>
    </div>
    <!-- 20220119 商品信息 -->
    <div class="goods-info">
      <div class="goods-info-main">
        <div class="flex flex-left goods-info-cont">
          <div class="goods-info-cont-l">
            <img :src="goodsPic[0]" alt="">
          </div>
          <div class="goods-info-cont-r">
            <div class="goods-info-cont-r-title">
              <div class="goods-info-cont-r-title-tag" :style="{background: 'url('+require('../../assets/img/goods/goods-tag.png')+')no-repeat center center',backgroundSize: 'cover'}">正品保障</div>{{goodsData.name}}
            </div>
            <div class="goods-info-cont-r-price">¥{{parseFloat(goodsData.salePrice/100).toFixed(2)}}</div>
            <div class="flex flex-left align-center goods-info-cont-r-originprice">
              <div class="goods-info-cont-r-originprice-l">市场价¥{{parseFloat(goodsData.originalPrice/100).toFixed(2)}}</div>
            </div>
          </div>
        </div>
        <div class="goods-info-line"></div>
        <div class="flex flex-between align-center goods-info-share">
          <div class="flex flex-left flex-wrap goods-info-share-tag">
            <span class="goods-info-share-tag-item" v-if="goodsData.score">
              会员得 {{goodsData.score}} 龙珠
            </span>
            <span class="goods-info-share-tag-item" v-if="goodsData.wiseScore">
              达人得 {{goodsData.wiseScore}} 龙珠
            </span>
            <!-- <span class="goods-info-share-tag-item goods-info-share-tag-item1">
              推广返佣 ¥{{parseFloat(item.returnAmount/100).toFixed(2)}}
            </span> -->
          </div>
          <div class="flex flex-right align-center goods-info-cont-r-originprice-r" @click="goShareFunc">
            <img src="../../assets/img/goods/share.png" alt="">分享
          </div>
        </div>
      </div>
    </div>
    <!-- 20220119 成为种草达人 -->
    <div class="goods-zcperson" v-if="isDaPerson == 0">
      <div class="flex flex-between align-center goods-zcperson-zc" @click="gotoProfitFunc">
        <div class="flex flex-left align-center goods-zcperson-zc-l">
          <img src="../../assets/img/goods/coupon-icon.png" alt="">
          <span v-if="goodsData.title">{{goodsData.title}}</span>
        </div>
        <img class="goods-zcperson-zc-r" src="../../assets/img/goods/right-white.png" alt="">
      </div>
    </div>
    <!-- 20220119 查看返佣规则 -->
    <div class="goods-zc" v-if="userType == '3' || userType == '4'" @click="readProfitFunc">
      <div class="flex flex-between align-center goods-zc-main" :style="{background: 'url('+require('../../assets/img/goods/goods-notice.png')+')no-repeat center center', backgroundSize: 'cover'}">
        <div class="goods-zc-l">*种草分享身边人，被拔草后奖励推广奖励金 ¥10.00/件起</div>
        <div class="flex flex-center align-center goods-zc-r">查看返佣规则</div>
      </div>
    </div>
    <!-- 优惠券 -->
    <div class="goods-coupon" v-if="couponInfo">
      <div class="flex flex-left goods-coupon-info" >
          <div class="flex flex-center align-center goods-coupon-info-l">
            ¥<span>
              {{parseFloat(couponInfo.value/100).toFixed()}}
            </span>
          </div>
          <div class="flex flex-between align-center goods-coupon-info-r">
            <div class="goods-coupon-info-r-l">
              <div class="goods-coupon-info-r-l-d1">
                {{couponInfo.name}}
              </div>
              <div class="goods-coupon-info-r-l-d2" v-if="couponInfo.validStartTime">{{couponInfo.validStartTime.substring(0,11)}}～{{couponInfo.validEndTime.substring(0,11)}}</div>
              <div class="goods-coupon-info-r-l-d2" @click="showXz = true;">点击查看使用须知</div>
            </div>
            <van-button type="primary" class="goods-coupon-info-r-btn" color="#E62129" round @click="receiveCouponFunc">立即领取</van-button>
          </div>
        </div>
    </div>
    <div class="goods-fenge"></div>
    <!-- 品牌 -->
    <div class="flex flex-between align-center goods-main-three" @click="goBrandFunc" style="margin-top:0px;">
      <div class="flex flex-left align-center goods-main-three-l">
        <img :src="goodsData.brandIcon ? goodsData.brandIcon: '../../assets/img/goods/logo.png'" alt="">
        {{goodsData.brandName}}
      </div>
      <img class="goods-main-three-r" src="../../assets/img/goods/right.png" alt="">
    </div>
    <!-- 商品详情 -->
    <div class="goods-detail" v-if="goodsData.content">
      <div class="goods-detail-cont">{{goodsData.content}}</div>
    </div>
    <van-swipe class="goods-banner" :autoplay="3000">
      <van-swipe-item v-for="image in goodsPic" :key="image">
        <img :src="image" />
      </van-swipe-item>
      <template #indicator="{ active }">
        <div class="flex flex-center align-center custom-indicator">{{ active + 1 }}/{{goodsPic.length}}</div>
      </template>
    </van-swipe>
    <!-- 主要内容 -->
    <div class="goods-main">
      <div class="goods-main-one" style="display: none;">
        <div class="flex flex-between goods-main-one-price">
          <div class="goods-main-one-price-l">
            <span class="goods-main-one-price-l-s1">¥</span>
            <span class="goods-main-one-price-l-s2">{{parseFloat(goodsData.salePrice/100).toFixed(2)}}</span>
            <span class="goods-main-one-price-l-s3">¥{{parseFloat(goodsData.originalPrice/100).toFixed(2)}}</span>
          </div>
          <div class="flex flex-right align-center goods-main-one-price-sale">
            <span v-if="goodsData.saleNum < 1000">{{goodsData.saleNum}}</span>
            <span v-if="goodsData.saleNum >= 1000 && goodsData.saleNum < 10000">{{goodsData.saleNum}}+&nbsp;</span>
            <span v-if="goodsData.saleNum >= 10000">{{goodsData.saleNum.substring(0,1)}}w+&nbsp;</span>
            人付款</div>
        </div>
        <div class="flex flex-left goods-main-one-profit" v-if="goodsData.cash">
          <span>推荐粉丝返 ¥{{parseFloat(goodsData.cash/100).toFixed(2)}}</span>
        </div>
        <div class="goods-main-one-name">
          {{goodsData.name}}
        </div>
        <div class="flex flex-between align-center goods-main-one-share">
          <div class="flex flex-left goods-main-one-share-l">
            <span class="goods-main-one-share-l-s1" v-if="goodsData.tag">{{goodsData.tag}}</span>
            <span class="goods-main-one-share-l-s2" v-if="goodsData.score">得{{goodsData.score}}龙珠</span>
          </div>
          <div class="flex flex-right align-center goods-main-one-share-r">
            <div class="flex flex-right align-center goods-main-one-share-r-d1" @click="goShareFunc">
              <img src="../../assets/img/goods/share.png" alt="">分享
            </div>
            <!--  -->
            <div class="flex flex-right align-center goods-main-one-share-r-d2" @click="collectGoodsFunc">
              <img src="../../assets/img/goods/sc.png" alt="" v-if="isLike == 2">
              <img src="../../assets/img/goods/sc-sel.png" alt="" v-if="isLike == 1">
              收藏
            </div>
          </div>
        </div>
        <div class="flex flex-left goods-main-one-coupon" v-if="couponInfo">
          <div class="flex flex-center align-center goods-main-one-coupon-l">
            ¥<span>{{parseFloat(couponInfo.value/100).toFixed()}}</span>
          </div>
          <div class="flex flex-between align-center goods-main-one-coupon-r">
            <div class="goods-main-one-coupon-r-l">
              <div class="goods-main-one-coupon-r-l-d1">{{couponInfo.name}}</div>
              <div class="goods-main-one-coupon-r-l-d2" v-if="couponInfo.validStartTime">{{couponInfo.validStartTime.substring(0,11)}}～{{couponInfo.validEndTime.substring(0,11)}}</div>
            </div>
            <van-button type="primary" class="goods-main-one-coupon-r-btn" color="#E62129" round @click="receiveCouponFunc">立即领取</van-button>
          </div>
        </div>
      </div>
      <div class="goods-main-two" style="display: none;">
        <div class="flex flex-between align-center goods-main-two-list" @click="goRefundPageFunc">
          <div class="goods-main-two-list-l">服务</div>
          <div class="flex flex-right align-center goods-main-two-list-r">
            正品保障 · 限时包邮<img src="../../assets/img/goods/right.png" alt="">
          </div>
        </div>
        <div class="flex flex-between align-center goods-main-two-list" v-if="userType == '3' || userType == '4'" @click="readProfitFunc">
          <div class="goods-main-two-list-l">返佣</div>
          <div class="flex flex-right align-center goods-main-two-list-r">
            查看返佣规则<img src="../../assets/img/goods/right.png" alt="">
          </div>
        </div>
        <div class="flex flex-between align-center goods-main-two-list" v-if="mallEntityGoodSpecList.length > 0">
          <div class="goods-main-two-list-l">已选</div>
          <div class="flex flex-right align-center goods-main-two-list-r" @click="openGuigeFunc">
              {{specName}}<img src="../../assets/img/goods/right.png" alt="">
          </div>
        </div>
      </div>
      <!-- 评价 -->
      <div class="goods-main-evaluation" style="display:none;">
        <div class="flex flex-between align-center goods-main-evaluation-top">
          <div class="goods-main-evaluation-top-l">宝贝评价（35）</div>
          <div class="flex flex-right align-center goods-main-evaluation-top-r">
            全部评价<img src="../../assets/img/goods/right.png" alt="">
          </div>
        </div>
        <div class="goods-main-evaluation-bot">
          <ul class="flex flex-left goods-main-evaluation-bot-ul" style="display:none;">
            <li class="goods-main-evaluation-bot-ul-li" v-for="item in 5" :key="item.id">
              <div class="flex flex-left align-center goods-main-evaluation-bot-ul-li-t">
                <div><img src="" alt=""></div>神**乐
              </div>
              <div class="goods-main-evaluation-bot-ul-li-b">
                <div>
                  评价内容评价内容评价内容评价内容评价内容
                  评价内容评价内容评价内容评价内容。
                  评价内容评价内容评价内容评价内容评价内容
                  评价内容评价内容评价内容评价内容。
                  评价内容评价内容评价内容评价内容评价内容
                  评价内容评价内容评价内容评价内容。
                </div>
              </div>
            </li>
          </ul>
          <!-- 暂无评价 -->
          <div class="flex flex-center flex-wrap align-center goods-main-evaluation-bot-nodata">
            <img src="../../assets/img/goods/nodata.png" alt="">
            <div class="goods-main-evaluation-bot-nodata-toast">当前暂无评价</div>
          </div>
        </div>
      </div>
      <div class="goods-main-four">
        <div class="flex flex-center align-center goods-main-four-title">
          <span></span>
          图文详情
          <span></span>
        </div>
        <!-- <div class="goods-main-four-content">
          {{goodsData.content}}
        </div> -->
        <div class="goods-main-four-detail goods-main-four-detail1"  v-if="remarkArr.length > 0" :class="isMoreDetail?'goods-main-four-detail1':''">
          <img v-for="item in remarkArr" :key="item.id" :src="item" alt="" style="width:100%;vertical-align: top;">
        </div>
        <!-- <div class="flex flex-center align-center goods-main-four-more"  v-if="remarkArr.length > 0" @click="readDetailFunc">
          {{isMoreDetail ? '点击收起详情':'点击查看更多详情'}}<img src="../../assets/img/goods/down.png" alt="">
        </div> -->
      </div>
      <div class="flex flex-between goods-main-five">
        <!-- <i v-if="tagIndex == 0"></i> -->
        <div class="goods-main-five-list" :class="tagIndex == 0 ? 'goods-main-five-list1' : ''" @click="selectTagFunc(0)">品牌其他商品</div>
        <!-- <div class="goods-main-five-list" :class="tagIndex == 1 ? 'goods-main-five-list1' : ''" @click="selectTagFunc(1)">爆款推荐<i v-if="tagIndex == 1"></i></div> -->
      </div>
      <div class="goods-main-six">
        <ul class="flex flex-left flex-wrap goods-main-six-ul" v-if="tagIndex == 0 && goodsList.length > 0">
          <li class="goods-main-six-ul-li" v-for="item in goodsList" :key="item.id" @click="gotoPageFunc(item.id)">
            <div class="flex flex-center align-center goods-main-six-ul-li-img" :style="{background: 'url('+farmPicFunc(item.picture)+')no-repeat center center', backgroundSize: 'cover'}">
            </div>

            <div class="goods-main-six-ul-li-name">{{item.name}}</div>

            <div class="flex flex-left flex-wrap goods-main-six-ul-li-yh" v-if="item.couponName">
              <span>{{item.couponName}}</span>
            </div>
            <div class="goods-main-six-ul-li-price">
              ¥<span class="goods-main-six-ul-li-price-s1">{{parseFloat(item.salePrice/100).toFixed(2)}}</span>
              <span class="goods-main-six-ul-li-price-s3">起</span>
              <span class="goods-main-six-ul-li-price-s2">灵龙价</span>
            </div>
          </li>
        </ul>
        <ul class="flex flex-left flex-wrap goods-main-six-ul" v-if="tagIndex == 1 && bkGoodsList.length > 0">
          <li class="goods-main-six-ul-li" v-for="item in bkGoodsList" :key="item.id" @click="gotoPageFunc(item.goodId)">
            <div class="flex flex-center align-center goods-main-six-ul-li-img" :style="{background: 'url('+farmPicFunc(item.goodPic)+')no-repeat center center', backgroundSize: 'cover'}">
            </div>

            <div class="goods-main-six-ul-li-name">{{item.goodName}}</div>

            <div class="flex flex-left flex-wrap goods-main-six-ul-li-yh" v-if="item.couponName">
              <span>{{item.couponName}}</span>
            </div>
            <div class="goods-main-six-ul-li-price">
              ¥<span class="goods-main-six-ul-li-price-s1">{{parseFloat(item.salePrice/100).toFixed(2)}}</span>
              <span class="goods-main-six-ul-li-price-s3">起</span>
              <span class="goods-main-six-ul-li-price-s2">灵龙价</span>
            </div>
          </li>
        </ul>
        <van-empty v-if="(tagIndex == 0 && goodsList.length == 0) || (tagIndex == 1 && bkGoodsList.length == 0)" description="暂无数据" />
        <div class="goods-main-six-btn" v-if="goodsList.length >= 6 || bkGoodsList.length >= 6">
          <van-button type="default" class="goods-main-six-btn-an" round @click="clickMoreFunc">查看更多商品</van-button>
        </div>
      </div>
    </div>
    <!-- 已选商品规格 -->
    <div class="flex flex-between align-center goods-guige">
      <span class="goods-guige-l">已选</span>
      <div class="flex flex-right align-center goods-guige-r" @click="openGuigeFunc">
        {{specName}}<img src="../../assets/img/goods/goods-s-r.png" alt="">
      </div>
    </div>
    <!-- 底部操作栏 -->
    <van-action-bar :safe-area-inset-bottom="true">
      <van-action-bar-icon @click="onClickIcon('home')" >
        <div class="goods-action">
          <img class="goods-action-img" src="../../assets/img/goods/home.png" alt="">
          <div class="goods-action-name">首页</div>
        </div>
      </van-action-bar-icon>
      <van-action-bar-icon @click="collectGoodsFunc" >
        <div class="goods-action">
          <img class="goods-action-img" src="../../assets/img/goods/sc1.png" alt="" v-if="isLike == 2">
          <img class="goods-action-img" src="../../assets/img/goods/sc2.png" alt="" v-if="isLike == 1">
          <div class="goods-action-name">收藏</div>
        </div>
      </van-action-bar-icon>
      <van-action-bar-button type="warning" @click="goShareFunc">
        <div class="goods-action">
          <div class="goods-action-title">立即分享</div>
          <!-- <div class="goods-action-jl" v-if="rewardIntergal > 0">奖励{{rewardIntergal}}积分</div> -->
        </div>
      </van-action-bar-button>
      <van-action-bar-button type="danger" @click="openGuigeFunc">
        <div class="goods-action">
          <div class="goods-action-title">立即购买</div>
          <div class="goods-action-jl" v-if="yhPrice > 0">省 ¥{{parseFloat(yhPrice/100).toFixed(2)}}</div>
        </div>
      </van-action-bar-button>
    </van-action-bar>
    <!-- sku选择 -->
    <goods-sku v-if="isGoodsSku" ref="goodssku" @buynumfunc="buyGoodsFunc"></goods-sku>
    <!-- 普通会员/达人 查看返佣规则 -->
    <van-popup v-model:show="showRules" position="center" :close-on-click-overlay="false" round>
      <div class="goods-rules" v-if="isDaPerson == '0'">
        <div class="flex flex-center align-center goods-rules-title"><span></span>提示<span></span></div>
        <div class="goods-rules-toast">目前只有达人才可查看</div>
        <div class="flex flex-between goods-rules-btn">
          <van-button plain type="primary" class="goods-rules-btn-item1" round color="#CCCCCC" @click="showRules=false">取消</van-button>
          <van-button type="primary" class="goods-rules-btn-item2" round color="#E62129" @click="applyDaPersonFunc">成为达人</van-button>
        </div>
      </div>
      <div class="goods-rules" v-if="isDaPerson == '1' || isDaPerson == '2'">
        <div class="flex flex-center align-center goods-rules-title"><span></span>提示<span></span></div>
        <div class="goods-rules-toast" v-if="mallEntityGoodSpecList.length > 0">
          <p style="margin-bottom:10px;">你的粉丝购买本商品</p>
          <p v-for="item in mallEntityGoodSpecList" :key="item.id" style="padding:2px 10px;">{{goodsData.spec1Name}}:{{item.spec1Attr}}，{{goodsData.spec2Name}}:{{item.spec2Attr}}规格可获得返佣¥{{parseFloat(item.wiseReturnAmount/100).toFixed(2)}}</p>
        </div>
        <div class="goods-rules-toast" v-else>
          <p style="padding:2px 10px;">你的粉丝购买本商品，可获得返佣¥{{parseFloat(item.returnAmount/100).toFixed(2)}}</p>
        </div>
        <div class="flex flex-between goods-rules-btn1">
          <van-button plain type="primary" class="goods-rules-btn1-item1" @click="showRules=false">我已知晓</van-button>
        </div>
      </div>
    </van-popup>

    <!-- 关注公众号 -->
    <div class="gz" v-if="isWxGz">
      <div class="gz-main">
        <div class="gz-t">
          <p class="flex flex-center align-center gz-t-p">
            <span></span>
            关注“灵龙惠生活”公众号
            <span></span>
          </p>
          <p  class="gz-t-p1">享受优质 1对1 服务</p>
        </div>
        <div class="gz-main-line" :style="{background: 'url('+require('../../assets/img/goods/gz-line.png')+')no-repeat center center', backgroundSize: 'cover'}"></div>
        <div class="gz-main-line1" :style="{background: 'url('+require('../../assets/img/goods/gz-line.png')+')no-repeat center center', backgroundSize: 'cover'}"></div>
        <div class="gz-b">
          <p class="gz-b-p">
            如需要使用龙珠抵扣、查物流跟踪、<br/>
            操作提现、查看退款等，必须关注公众号
          </p>
          <img src="../../assets/img/goods/gz.png" alt="">
        </div>
        <div class="gz-main-close" :style="{background: 'url('+require('../../assets/img/goods/close.png')+')no-repeat center center', backgroundSize: 'cover'}" @click="isWxGz = false;"></div>
      </div>
    </div>
    <!-- 优惠券须知 -->
    <van-popup v-model:show="showXz" position="bottom" :style="{ height: '45%' }" closeable close-icon-position="top-right" round>
      <div class="flex flex-center align-center goods-wtitle">
        <span></span>优惠券使用说明<span></span>
      </div>
      <div class="goods-winfo">
        <div class="flex flex-left goods-winfo-item">
          <div class="goods-winfo-item-left"></div>
          <div class="goods-winfo-item-right">
            <div class="goods-winfo-item-right-title">使用有效时间：</div>
            <!-- <div class="goods-winfo-item-right-cont">{{couponInfo.validStartTime.substring(0,11)}}～{{couponInfo.validEndTime.substring(0,11)}}</div> -->
          </div>
        </div>
        <div class="flex flex-left goods-winfo-item">
          <div class="goods-winfo-item-left"></div>
          <div class="goods-winfo-item-right">
            <div class="goods-winfo-item-right-title">使用规则：</div>
            <div class="goods-winfo-item-right-cont">每位用户只能使用1张优惠券，用完即止</div>
          </div>
        </div>
        <div class="flex flex-left goods-winfo-item">
          <div class="goods-winfo-item-left"></div>
          <div class="goods-winfo-item-right">
            <div class="goods-winfo-item-right-title">优惠券使用范围：</div>
            <div class="goods-winfo-item-right-cont">只针对{{couponInfo?couponInfo.goodsName:''}}产品使用</div>
          </div>
        </div>
        <div class="flex flex-left goods-winfo-item">
          <div class="goods-winfo-item-left"></div>
          <div class="goods-winfo-item-right">
            <div class="goods-winfo-item-right-title">和其他营销规则：</div>
            <div class="goods-winfo-item-right-cont">优惠券不可使用于秒杀商品、可以和龙珠抵扣共同使用</div>
          </div>
        </div>
      </div>
      <div class="goods-wcopy">灵龙平台具有优惠券使用最终解释权。</div>
    </van-popup>
  </div>
</template>
<script src="./index.js"></script>
<style>
.goods-main-four-detail img {
    width: 100%;
    height: auto;
    vertical-align: top;
}
</style>
<style lang="scss" scoped>
@import "./index.scss";
</style>